<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="8">
                <el-card shadow="hover" class="mgb20" style="height: 252px">
                    <div class="user-info">
                        <img
                            src="../assets/img/img.jpg"
                            class="user-avator"
                            alt
                        />
                        <div class="user-info-cont">
                            <div class="user-info-name">{{ name }}</div>
                            <div>{{ role }}</div>
                        </div>
                    </div>
                    <div class="user-info-list">
                        上次登录时间：
                        <span>2019-11-01</span>
                    </div>
                    <div class="user-info-list">
                        上次登录地点：
                        <span>上海</span>
                    </div>
                </el-card>
                <el-card shadow="hover" style="height: 252px">
                    <template #header>
                        <div class="clearfix">
                            <span>各课程解锁比例</span>
                        </div>
                    </template>
                    <div id="smartAnalyse" :style="{
                        width: '100%',
                        height: '20rem',
                        marginTop: '1rem',
                    }"></div>
                </el-card>
            </el-col>
            <el-col :span="16">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-row :gutter="20" class="mgb20">
                            <el-col :span="24" class="fission-card" style="" v-for="(item,index) of creditInfo" :key="index">
                                <!-- <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="index === 'subs_num' ? '查看裂变机构' : ''"
                                    placement="bottom"
                                    :disabled="index === 'subs_num' ? false : true"
                                > -->
                                    <el-card
                                        shadow="hover"
                                        :body-style="{ padding: '0px' }"
                                    >
                                        <div class="grid-content" :class="item.color">
                                            <i class="grid-con-icon" :class="item.icon"></i>
                                            <div class="grid-cont-right">
                                                <div class="grid-num">{{ item.num }}</div>
                                                <div>{{ item.text }}</div>
                                            </div>
                                        </div>
                                    </el-card>
                                <!-- </el-tooltip> -->
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="12">
                        <el-card class="card-pick">
                            <div class="ranking-box">
                                <div class="top-box">
                                    <!-- <span title="切换排行榜" class="el-icon-refresh el-icon-refresh cut"></span> -->
                                    <el-switch
                                    class="cut"
                                    style="display: inline-block"
                                    v-model="rankingType"
                                    active-color="#13ce66"
                                    inactive-color="#2d8cf0"
                                    active-text="充值"
                                    inactive-text="消费"
                                    @change="cutRanking"
                                    >
                                    </el-switch>
                                    <span class="tag-btn" @click="openTagBox">标签筛选<i class="icon el-icon-caret-bottom"></i></span>
                                    <p class="title">机构总{{ rankingType ? "充值" : "消费" }}排行榜</p>
                                    <el-row class="bureau-tag-box" :class="isOpenTagsBox?'open':''">
                                        <el-col :span="6" v-for="(item, index) in bureauTags" :key="index">
                                            <div class="tag-item" @click="setDefaultBureauTag(index)">
                                                {{ item.name }}
                                                <span v-if="item.type" class="dian"></span>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row class="time-option">
                                        <el-col class="time-item"
                                                :class="checkInterval === index ? 'checked' : ''"
                                                :span="8" v-for="(item, index) in rankingTimeInterval"
                                                :key="index"
                                                @click="checkIntervalMeth(index)"
                                                >
                                            {{ item.name }}
                                        </el-col>
                                    </el-row>
                                    <el-row class="top-con-box">
                                        <el-col :span="8" v-for="index in 3 " :key="index">
                                            <div class="the-box" :class="topThreeItem(index)">
                                                <div class="head-img-box">
                                                    <!-- <img src="../assets/img/img.jpg" alt=""> -->
                                                    <div class="head-img">
                                                        <img :src="rankingNow[topThreeItem(index)].avatar_url" alt="">
                                                    </div>
                                                    <div class="crown"></div>
                                                </div>
                                                <p class="name" :title="rankingNow[topThreeItem(index)].bureau_name">{{ rankingNow[topThreeItem(index)].bureau_name }}</p>
                                            </div>
                                            <p class="num">{{ rankingNow[topThreeItem(index)].money }}</p>
                                        </el-col>
                                    </el-row>
                                </div>
                                <div class="con-box">
                                    <ul>
                                        <li v-for="(item, index) in rankingNow.data" :key="index">
                                            <el-row>
                                                <el-col :span="2" class="serial">
                                                    {{ index + 4 }}
                                                </el-col>
                                                <el-col :span="5">
                                                    <div class="head-img">
                                                        <img :src="item.avatar_url" alt="">
                                                    </div>
                                                </el-col>
                                                <el-col :span="9" class="name">
                                                    {{ item.bureau_name }}
                                                </el-col>
                                                <el-col :span="8" class="num">{{ item.money }}</el-col>
                                            </el-row>
                                            <hr v-if="index !== rankingNow.data.length-1">
                                        </li>
                                    </ul>
                                    <div class="nothing" v-if="rankingNow.data.length === 0">
                                        <div class="img-box">
                                            <img src="../assets/img/nothing.png" alt="">
                                        </div>
                                        <p>暂无数据</p>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { fetchData } from "../api/api";
import moment from 'moment'
export default {
    name: "dashboard",
    data() {
        return {
            name: localStorage.getItem("ms_username"),
            // 机构总充值
            creditDepositRank: {
                page: 1,
                limit: 10,
                list: [],
            },
            // 机构总消费
            creditConsumptionRank: {
                page: 1,
                limit: 99999,
                list: [],
            },
            // 各课程解锁比例
            courseUnlockedRatio: {
                page: 1,
                limit: 99999,
                list: []
            },
            // 基础信息
            creditInfo: {
                total_deposit_credit: {
                    num: 0,
                    text: '累计总充值',
                    icon: 'el-icon-coin',
                    color: 'grid-con-1',
                },
                total_consumed_credit: {
                    num: 0,
                    text: '累计总消费',
                    icon: 'el-icon-s-finance',
                    color: 'grid-con-2',
                },
                subs_num: {
                    num: 0,
                    text: '已裂变数',
                    icon: 'el-icon-share',
                    color: 'grid-con-3',
                },
                bureau_num: {
                    num: 0,
                    text: '总机构数量',
                    icon: 'el-icon-school',
                    color: 'grid-con-1',
                },
                group_num: {
                    num: 0,
                    text: '班级数量',
                    icon: 'el-icon-s-claim',
                    color: 'grid-con-2',
                },
                student_num: {
                    num: 0,
                    text: '学生数量',
                    icon: 'el-icon-user',
                    color: 'grid-con-3',
                },
                teacher_num: {
                    num: 0,
                    text: '老师数量',
                    icon: 'el-icon-s-custom',
                    color: 'grid-con-1',
                },
            },
            smartAnalyse:'',
            // 排行榜
            rankingTimeInterval: {
                week: {
                    name: '周榜',
                    interval: '',
                    recharge: {
                        name: '充值',
                        first: {},
                        second: {},
                        thirdly: {},
                        data: []
                    },
                    consume: {
                        name: '消费',
                        first: {},
                        second: {},
                        thirdly: {},
                        data: []
                    }
                },
                month: {
                    name: '月榜',
                    interval: '',
                    recharge: {
                        name: '充值',
                        first: {},
                        second: {},
                        thirdly: {},
                        data: []
                    },
                    consume: {
                        name: '消费',
                        first: {},
                        second: {},
                        thirdly: {},
                        data: []
                    }
                },
                year: {
                    name: '年榜',
                    interval: '',
                    recharge: {
                        name: '充值',
                        first: {},
                        second: {},
                        thirdly: {},
                        data: []
                    },
                    consume: {
                        name: '消费',
                        first: {},
                        second: {},
                        thirdly: {},
                        data: []
                    }
                }
            },
            checkInterval: 'year',
            checkType: 'recharge',
            defaultObj: {
                bureau_id: 0,
                bureau_name: '',
                money: 0,
                avatar_url: ''
            },
            rankingType: true,
            bureauTags: [],
            isOpenTagsBox: false
        };
    },
    components: {
        // Schart,
        // echarts
    },
    computed: {
        role() {
            return this.name === "admin" ? "超级管理员" : "普通用户";
        },
        tipContent:index => {
            return index === 'subs_num' ? '查看裂变机构' : '';
        },
        topThreeItem(){
            return index => {
                let item = '';
                switch (index) {
                    case 1:
                        item = "second";
                        break;
                    case 2:
                        item = "first";
                        break;
                    case 3:
                        item = "thirdly";
                        break;
                    default:
                        break;
                }
                return item;
            }
        },
        rankingNow() {
            return this.rankingTimeInterval[this.checkInterval][this.checkType];
        },
        rankingUrl() {
            return this.rankingType ? "/admin/v1/getCreditDepositRank" : "/admin/v1/getCreditConsumptionRank";
        }
    },
    created() {
        this.getData();
        this.getRankingTimeInterval();
        // console.log(this.rankingTimeInterval[this.checkInterval][this.checkType][this.topThreeItem].money);
    },
    methods: {
        getData() {
            this.getCreditConsumptionRank();
            this.getCourseUnlockedRatio();
            this.getCreditInfo();
            this.getGuobaoInfo();
            this.getBureauTags();
        },
        changeDate() {
            const now = new Date().getTime();
            this.data.forEach((item, index) => {
                const date = new Date(now - (6 - index) * 86400000);
                item.name = `${date.getFullYear()}/${
                    date.getMonth() + 1
                }/${date.getDate()}`;
            });
        },
        goTo(url) {
            this.$router.push({ path: "/", name: url });
        },
        // 获取排行榜数据
        getRankingData () {
            fetchData(
                {
                    page: this.creditDepositRank.page,
                    limit: this.creditDepositRank.limit,
                    start_time: this.rankingTimeInterval[this.checkInterval].interval,
                    end_time: moment().format('YYYY-MM-DD'),
                }, this.rankingUrl , "GET"
            ).then((res) => {
                let list = res.data.list,
                    len = list.length;
                this.rankingNow.first = len > 0 ? list[0]: this.defaultObj;
                this.rankingNow.second = len > 1 ? list[1] : this.defaultObj;
                this.rankingNow.thirdly = len > 2 ? list[2] : this.defaultObj;
                this.rankingNow.data = len > 3 ? list.splice(3) : [];
            });
        },
        // 后台机构总消费排行榜
        getCreditConsumptionRank() {
            fetchData(
                {
                    page: this.creditConsumptionRank.page,
                    limit: this.creditConsumptionRank.limit,
                },
                "/admin/v1/getCreditConsumptionRank",
                "GET"
            ).then((res) => {
                let data = res.data;
                this.creditConsumptionRank.page = data.page;
                this.creditConsumptionRank.list = data.list;
            });
        },
        // 各课程解锁比例
        getCourseUnlockedRatio() {
            fetchData(
                {
                    page: this.courseUnlockedRatio.page,
                    limit: this.courseUnlockedRatio.limit,
                },
                "/admin/v1/getCourseUnlockedRatio",
                "GET"
            ).then((res) => {
                let data = res.data;
                this.courseUnlockedRatio.page = data.page;
                this.courseUnlockedRatio.list = data.list;
                this.testEcharts(data.list);
            });
        },
        testEcharts(ratioData) {
            let data = [];
            ratioData.forEach((item)=>{
                data.push({
                    value: item.num,
                    name: item.course_name,
                });
            });
            this.smartAnalyse = this.$root.echarts
            .init(document.getElementById("smartAnalyse"))
            .setOption({
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    type: "scroll",
                    orient: "vertical",
                    top: '5%',
                    right: "0"
                },
                series: [
                    {
                    name: '信息',
                    type: 'pie',
                    width: "130%",
                    height: "130%",
                    radius: ['40%', '70%'],
                    center: ['25%','35%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            formatter: '{b}\n\n{d}%',
                            fontSize: '20',
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: data
                    }
                ]
            });
        },


        getCreditInfo() {
            fetchData(
                {},
                "/admin/v1/getCreditInfo",
                "GET"
            ).then((res) => {
                let data = res.data;
                this.creditInfo.total_deposit_credit.num = data.total_deposit_credit;
                this.creditInfo.total_consumed_credit.num = data.total_consumed_credit;
                this.creditInfo.subs_num.num = data.subs_num;
            });
        },
        getGuobaoInfo() {
            fetchData(
                {},
                "/admin/v1/getGuobaoInfo",
                "GET"
            ).then((res) => {
                let data = res.data;
                this.creditInfo.bureau_num.num = data.bureau_num;
                this.creditInfo.group_num.num = data.group_num;
                this.creditInfo.student_num.num = data.student_num;
                this.creditInfo.teacher_num.num = data.teacher_num;
            });
        },
        numFilter (value) {
            // 截取当前数据到小数点后两位
            let realVal = parseFloat(value).toFixed(2)
            return realVal
        },
        // 获取时间区间
        getRankingTimeInterval() {
            let dateArr = moment().format('YYYY-MM-DD').split('-');
            this.rankingTimeInterval.year.interval = dateArr[0]+"-01-01";
            this.rankingTimeInterval.month.interval = dateArr[0]+"-"+dateArr[1]+"-01";

            let weekOfday = parseInt(moment().format('d'));
            this.rankingTimeInterval.week.interval = moment().subtract(weekOfday-1, 'days').format('YYYY-MM-DD');
            this.getRankingData();
        },
        // 切换排行榜类型
        cutRanking() {
            this.getRankingData();
        },
        // 切换时间区间
        checkIntervalMeth(index) {
            this.checkInterval = index;
            this.getRankingData();
        },
        // 获取全部的机构标签
        getBureauTags() {
            fetchData(
                { page: 1, limit: 999999, category: 'bureau' },
                "/bureau/v1.2/listTags",
                "GET"
            ).then((res) => {
                this.bureauTags = res.data.list
            });
        },
        // 修改默认标签
        setDefaultBureauTag(index) {
            fetchData(
                { id: this.bureauTags[index].id, type: Math.abs(this.bureauTags[index].type - 1) },
                "/bureau/v1.2/editTag",
                "GET"
            ).then(() => {
                this.getBureauTags();
                this.getRankingData();
            });
        },
        openTagBox() {
            this.isOpenTagsBox = !this.isOpenTagsBox;
        }
    }
};
</script>

<style lang="scss" scoped>
.schedule {
    height: 330px;
    overflow-y: auto;
    .item-box {
        margin-bottom: 1.5rem;
    }
}

.card-pick {
    ::v-deep .el-card__body {
        padding: 0;
    }
    .title {
        font-size: 16px;
        background: #f25e43;
        color: #fff;
        padding: 18px 25px;
    }
    .ranking {
        height: 32.5rem;
        overflow-y: auto;
        .item {
            padding: 15px 25px;
            border-bottom: 1px #ddd solid;
            cursor: pointer;
            &:hover {
                background: #f3c9c2;
                color: #fff;
                font-size: 20px;
                .num {
                    color: #fff;
                    font-size: 25px;
                }
            }
            i {
            }
            .text {
                margin-left: 25px;
            }
            .num {
                font-size: 20px;
                color: rgb(100, 213, 114);
                float: right;
            }
        }
    }
}
.right {
    float: right;
}

.fission-card {
    cursor: pointer;
    margin-bottom: 10px;
}

.el-row {
    margin-bottom: 20px;
}

.grid-content {
    display: flex;
    align-items: center;
    height: 100px;
}

.grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #999;
}

.grid-num {
    font-size: 30px;
    font-weight: bold;
}

.grid-con-icon {
    font-size: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #fff;
}

.grid-con-1 .grid-con-icon {
    background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
    background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
    background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
    color: rgb(242, 94, 67);
}

.user-info {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 2px solid #ccc;
    margin-bottom: 20px;
}

.user-avator {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.user-info-cont {
    padding-left: 50px;
    flex: 1;
    font-size: 14px;
    color: #999;
}

.user-info-cont div:first-child {
    font-size: 30px;
    color: #222;
}

.user-info-list {
    font-size: 14px;
    color: #999;
    line-height: 25px;
}

.user-info-list span {
    margin-left: 70px;
}

.mgb20 {
    margin-bottom: 20px;
}

.todo-item {
    font-size: 14px;
}

.todo-item-del {
    text-decoration: line-through;
    color: #999;
}

.schart {
    width: 100%;
    height: 300px;
}
</style>
<style lang="scss" scoped>
    * {
        margin: 0;
        padding: 0;
    }
    .ranking-box {
        .top-box {
            text-align: center;
            background-color: #ee6666;
            padding: 13px;
            position: relative;
            .title {
                background-color: inherit;
                .el-switch__label {
                    font-size: 16px;
                }
            }
            .time-option {
                display: inline-block;
                border: 1px solid #fff;
                border-radius: 50px;
                color: #fff;
                overflow: hidden;
                margin-bottom: 43px;
                .time-item {
                    cursor: pointer;
                    padding: 6px 30px;
                    &.checked {
                        background-color: #fff;
                        color: #ee6666;
                    }
                }
            }
            .top-con-box {
                background-color: #fff;
                border-radius: 5px;
                margin: 0;
                padding: 1rem 0;
                .the-box {
                    margin-top: 1px;
                    padding-top: 23%;
                    .head-img-box{
                        position: relative;
                        .crown {
                            background-image: url("../assets/img/OIP-C.png");
                            background-size: 453%;
                            background-position-x: 96%;
                            background-position-y: 32%;
                            position: absolute;
                            width: 40px;
                            height: 34px;
                            top: -35px;
                            left: 38%;
                        }
                    }
                    .head-img {
                        width: 45%;
                        margin: 0 auto;
                        border-radius: 50%;
                        overflow: hidden;
                        outline: 1px solid #eee;
                        .name {
                            color: #666;
                        }
                        img {
                            display: block;
                            width: 100%;
                        }
                    }
                }
                .first {
                    margin-top: -44px;
                    background-color: #fff;
                    border-radius: 5px;
                    padding-top: 34%;
                    position: relative;
                    .crown {
                        background-position-y: 32%!important;
                    }
                    .head-img {
                        width: 60%;
                        position: relative;
                        outline: 3px solid #f7b21e;
                        &::after {
                            content: '';
                            // background-image: url("../assets/img/");
                        }
                    }
                    &::after {
                        content: "";
                        border-right: 9px solid transparent;
                        border-top: 10rem solid #ffeeef;
                        position: absolute;
                        right: -9px;
                        top: 28px;
                    }
                    &::before {
                        content: "";
                        border-left: 9px solid transparent;
                        border-top: 10rem solid #ffeeef;
                        position: absolute;
                        left: -9px;
                        top: 28px;
                    }
                    & + .num {
                        color: #E6A23C;
                    }
                }
                .second .crown {
                    background-position-y: 44%!important;
                }
                .thirdly .crown{
                    background-position-y: 56%!important;
                }
                p {
                    margin-top: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .num {
                    font-size: 23px;
                    font-weight: bold;
                    color: #555;
                }
            }
            .cut {
                color: #fff;
                font-size: 24px;
                cursor: pointer;
                right: 3%;
                top: 5%;
                position: absolute;
            }
            .tag-btn {
                position: absolute;
                color: #fff;
                left: 15px;
                top: 20px;
                cursor: pointer;
                .icon {
                    transition: rotate(0deg);
                }
            }
            .bureau-tag-box {
                background: #fff;
                margin-bottom: 1rem;
                border-radius: 12px;
                padding: 0rem;
                max-height: 0rem;
                transition: max-height 0.4s, padding 0.4s;
                overflow: hidden;
                .tag-item {
                    position: relative;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    padding: 5px;
                    background: #f2f2f2;
                    margin-right: 10px;
                    cursor: pointer;
                    .dian {
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        background: #ee6666;
                        border-radius: 50%;
                        top: 0;
                        right: 0;
                    }
                }
                &.open {
                    max-height: 8rem;
                    padding: 1rem;
                    overflow: auto;
                }
            }
        }
        .con-box {
            overflow-y: scroll;
            height:60vh;
            ul {
                li {
                    .el-row {
                        margin: 0;
                        text-align: center;
                        .el-col {
                            line-height: 75px;
                            color: #888;
                            font-size: 18px;
                        }
                        .serial {
                            text-align: right;
                        }
                        .head-img {
                            position: relative;
                            width: 50%;
                            top: 50%;
                            transform: translateY(-50%);
                            margin: 0 auto;
                            border-radius: 50%;
                            overflow: hidden;
                            line-height: 75px;
                            aspect-ratio: 1/1;
                            img {
                                display: block;
                                width: 100%;
                            }
                        }
                        .name {
                            color: #000;
                            text-align: left;
                        }
                        .num {
                            color: #E6A23C;
                        }
                    }
                    hr {
                        border-color: #fff;
                        width: 88%;
                        margin: 0 auto;
                    }
                }
            }
            .nothing {
                text-align: center;
                .img-box {
                    width: 20%;
                    margin: 0 auto;
                    img {
                        display: inline-block;
                        width: 100%;
                    }
                }
                p {
                    padding-bottom: 10px;
                }
            }
        }
    }
    ::v-deep .el-switch__label.is-active {
        color: #fff;
    }
</style>
